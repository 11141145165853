<template>
    <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="serviceLevel-container common-padding">
        <div class="title-main">
            <p><span></span><span class="normal">客服等级设置</span></p>
            <div>
                <el-button class="common-screen-btn common-noMarginLeft" type="primary" @click="() => { addEditShow('') }">新增客服等级</el-button>
            </div>
        </div>
        
        <div class="common-padding">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="serviceLevel" label="客服等级名称"></el-table-column>
                <el-table-column prop="basePay" label="基本工资"></el-table-column>
                <!-- <el-table-column prop="coefficient" label="提成系数"></el-table-column> -->
                <el-table-column prop="actualDay" label="操作">
                    <template slot-scope="scope">
                        <div class="table-btn">
                            <i class="iconfont icon-xiangshangyuanjiantoushangjiantouxiangshangmianxing" v-if="scope.$index != 0" @click="() => { getSerLevelSort(scope.row.id, '-1') }"></i>
                            <i class="iconfont icon-xiangshangyuanjiantoushangjiantouxiangshangmianxing color999" v-if="scope.$index == 0"></i>
                            <i class="iconfont icon-xiangxiayuanjiantou" v-if="(scope.$index + 1) != tableData.length" @click="() => { getSerLevelSort(scope.row.id, '1') }"></i>
                            <i class="iconfont icon-xiangxiayuanjiantou color999" v-if="(scope.$index + 1) == tableData.length"></i>
                            <el-button style="margin: 0 12px" type="text" @click="() => { addEditShow(scope.row) }">编辑</el-button>
                            <el-button style="margin: 0 12px" type="text" @click="() => { deleteShow(scope.row.id) }">删除</el-button>
                            <!-- <el-popconfirm placement="top" title="确定删除吗？" @confirm="() => deleteSerLevel(scope.row.id)">
                                <el-button type="text" size="small" slot="reference">删除</el-button>
                            </el-popconfirm> -->
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog v-loading="addLoading" element-loading-text="拼命加载中..." v-dialogDrag title="客服等级" :append-to-body="true" :visible.sync="addSerLevelVisible" width="520px" >
            <div>
                <el-form :model="ruleFormAdd" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm" >
                    <el-form-item label="客服等级名称：" prop="serviceLevel">
                        <el-input class="common-screen-input_80" type="text" v-model="ruleFormAdd.serviceLevel" placeholder="客服等级名称" maxLength="50"></el-input>
                    </el-form-item>
                    <el-form-item label="基础工资：" prop="basePay">
                        <el-input class="common-input-YUAN" type="number"  @mousewheel.native.prevent v-model="ruleFormAdd.basePay" placeholder="基础工资" maxLength="50"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="提成系数：" prop="coefficient">
                        <el-input class="common-screen-input_80" type="number"  @mousewheel.native.prevent v-model="ruleFormAdd.coefficient" placeholder="提成系数" maxLength="50"></el-input>
                    </el-form-item> -->
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="addSerLevelVisible = false" >取 消</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => { submitForm() }">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { getSerLevelList, getSerLevelSort, addSerLevel, editSerLevel, deleteSerLevel } from "../../service/portrait.js"
import { rulePrice, ruleNum0 } from "../../utils/index.js"
import { mapState } from "vuex";
export default {
    components: {},
    data() {
        return {
            loading: false,
            // BreadcrumbData: [
            //     { title: "首页", isLink: true, url: "/index" },
            //     { title: "组织", isLink: false },
            //     { title: "客服等级设置", isLink: false }
            // ],
            // companyId: this.$sto.get(Config.constants.userInfo).company_id, // 所属公司id
            companyId: '', // 所属公司id
            tableData: [], // 列表数据
            ruleFormAdd: { // 新增等级参数
                serviceLevel: '',
                basePay: '',
                coefficient: ''
            },
            rules: { // 新增校验
                serviceLevel: [{ required: true, message: "请填写客服等级名称", trigger: "change" }],
                basePay: [
                    { required: true, message: "请填写基础工资", trigger: "change" },
                    { validator: rulePrice, trigger: "change" },
                ],
                coefficient: [
                    { required: true, message: "请填写提成系数", trigger: "change" },
                    { validator: ruleNum0, trigger: "change" },
                ]
            },
            addSerLevelVisible: false, // 新增弹窗
            addLoading: false,
        }
    },
    computed: {
        ...mapState(["comId"])
    },
    created () {
        this.getComId()
    },
    methods: {
        getComId() { // 获取comID&&请求列表
            setTimeout(() => {
                this.companyId = this.comId
                this.getSerLevelList()
            },400)
        },
        deleteShow(id) {
            this.$confirm('确定删除该条客服等级吗？', '确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                customClass: 'common-deleteMessage',
                type: 'warning'
            }).then(() => {
                this.deleteSerLevel(id)
            }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消'
            });          
            });
        },
        async deleteSerLevel(id) { // 删除客服等级
            let { code } = await deleteSerLevel({ id })
            if (code == 200) {
                this.getSerLevelList()
                this.$message.success('删除成功')
            }
        },
        addEditShow(row) { // 新增编辑弹窗
            if (row) {
                this.ruleFormAdd = {
                    id: row.id,
                    serviceLevel: row.serviceLevel,
                    basePay: row.basePay,
                    coefficient: row.coefficient
                }
            } else {
                this.ruleFormAdd = {
                    serviceLevel: '',
                    basePay: '',
                    coefficient: ''
                }
                if (this.$refs["ruleForm"]) {this.$refs["ruleForm"].resetFields();}
            }
            this.addSerLevelVisible = true
        },
        submitForm() { // 新增客服等级
            this.$refs["ruleForm"].validate(async (valid) => {
                if (valid) {
                    let ruleFormAdd = {...this.ruleFormAdd}
                    ruleFormAdd.companyId = this.companyId
                    this.addLoading = true
                    let code
                    if (ruleFormAdd.id) {
                        code = (await editSerLevel(ruleFormAdd)).code
                    } else {
                        code = (await addSerLevel(ruleFormAdd)).code
                    }
                    this.addLoading = false
                    if ( code == 200 ) {
                        this.addSerLevelVisible = false;
                        this.getSerLevelList();
                        if (ruleFormAdd.id) {
                            this.$message.success("编辑成功");
                        } else {
                            this.$message.success("新增成功");
                        }
                    }
                } else {
                    return false;
                }
            });
        },
        async getSerLevelSort(id, type) { // 客服等级-排序
            this.loading = true
            let { code } = await getSerLevelSort({id, type})
            this.loading = false
            if ( code == 200 ) {
                this.getSerLevelList()
                this.$message.success('操作成功')
            }
        },
        async getSerLevelList() {
            this.loading = true
            let { data } = await getSerLevelList({ companyId: this.companyId })
            this.loading = false
            this.tableData = data || []
        }
    }
}
</script>
<style lang="less" scoped>
.serviceLevel-container {
    text-align: left;
    .common-padding {
        padding: 32px;
    }
    .title-main {
        display: flex;
        align-items: center;
        padding: 0 32px;
        box-sizing: border-box;
        height: 72px;
        border-bottom: 1px solid #eee;
        justify-content: space-between;
        p {
        font-size: 20px;
        color: #666;
            span {
                font-size: 20px;
                color: #666;
                cursor: pointer;
            }
            .normal {
                font-size: 24px;
                color: #101010;
                cursor: auto;
            }
        }
    }
    .table-btn {
        display: flex;
        align-items: center;
    }
    .color999 {
        color: #999 !important;
    }
    .iconfont {
        cursor: pointer;
        font-size: 18px;
        margin-right: 4px;
        color: #1890ff;
    }
    /deep/.el-table--border th {
        background: #f7f7f7;
    }
    .title {
        box-sizing: border-box;
        padding: 12px 0 28px 0;
        height: 68px;
        color: #333;
        font-size: 28px;
        line-height: 28px;
        position: relative;
        &::after {
            content: ' ';
            display: block;
            width: 120%;
            position: absolute;
            bottom: 0;
            left: -10%;
            height: 1px;
            background: #ccc;
        }
    }
}
</style>